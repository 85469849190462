document.addEventListener('WebViewJavascriptBridgeReady', function (event) {
    var bridge = event.bridge

    bridge.init(function (data, responseCallback) {
        if (responseCallback) {
            responseCallback("Right back atcha!")
        }
    })

    if (endsWith(window.location, '/login'))
        bridge.callHandler('OpenNativeLogin', null, function (response) {
        });

    $('html').on('click', 'a[target=_blank]', function (e) {
        var href = $(this).attr('href');
        bridge.callHandler('OpenNativeWebView', href, function (response) {
        });
        e.preventDefault();
    });


}, false);

function endsWith(str, suffix) {
    var patt = new RegExp("\\" + suffix + "$");
    return patt.test(str);
}


// GET IMAGE LOADING
var loading = $('#loadingImage').html();

function toggle_menu_flutuante_period() {
    $('#menu-flutuante-period-custom').toggle();
    $('#menu-flutuante-period-standard').toggle();
    if ($('#menu-flutuante-period-custom').is(':visible')) {
        $('.menu-flutuante-dropdown').css('width', '240px');
        $('.menu-flutuante-dropdown').css('margin-left', '-120px');
    } else {
        $('.menu-flutuante-dropdown').css('width', '190px');
        $('.menu-flutuante-dropdown').css('margin-left', '-95px');
    }
}

function clone(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}

function resizeBody() {
    if ($('body').height() < $(window).height()) {
        $('body').css('height', '120%');
    }
}

function iconLoading() {
    return '<i class="fa fa-spinner fa-pulse loading"></i>';
}

function toggleButtonLoading(buttonId) {
    b = $(buttonId);
    if (b.is(':disabled')) {
        b.removeAttr('disabled');
        b.find('i.loading').each(function () {
            $(this).remove();
        });
    } else {
        b.attr('disabled', 'disabled');
        b.append(iconLoading());
    }
}

function toggleDivLoading(d) {
    if (d.hasClass('loading')) {
        d.removeClass('loading');
        d.find('i.loading').each(function () {
            $(this).remove();
        });
    } else {
        d.addClass('loading');
        d.append(iconLoading())
    }
}

function readJsonResponse(j) {
    if (j.error) {
        $.notify({message: j.response}, {type: 'danger'});
    } else {
        $.notify(j.response);
    }
    if (j.next_page && j.next_page != '' && j.next_page != null) {
        $.notify('Você será redirecionado' + iconLoading());
        setTimeout(function () {
            window.location = j.next_page;
        }, 3000);

    }
}

$(function () {

    setInterval(function () {
        $(".alert-success, .alert-warning").each(function () {
            if (!$(this).hasClass('no-close')) {
                $(this).delay(80000).slideUp(300);
            }
        });
        $(".alert-danger").each(function () {
            if (!$(this).hasClass('no-close') && $(this).attr("id") != "accountDenyAtt") {
                $(this).delay(80000).slideUp(300);
            }
        });
    }, 100);

    $.expr[":"].Contains = $.expr.createPseudo(function (arg) {
        return function (elem) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });

    $.notifyDefaults({
        type: 'success',
        offset: {
            x: 20,
            y: 70
        }
    });

    $(document).ajaxComplete(function () {
        resizeBody();
    });

    // BEGIN SIDEBAR
    var height = $("#imgClientLogo").css('height');

    $('.page-sidebar-menu li a').click(function () {
        //$('.sub-menu').hide();
        $(this).parent().children('.sub-menu').toggle();
        $('.page-sidebar').find('.fa-angle-down').removeClass('fa-angle-down').addClass('fa-angle-up');
        if ($(this).parent().children('.sub-menu').is(':visible')) {
            $('.page-sidebar-menu').height("");
            var calc = $('.page-sidebar-menu').height();
            $(this).parent().find('i:last-child').removeClass('fa-angle-up').addClass('fa-angle-down');
            $('.page-sidebar-menu').css('height', calc);
        } else {
            $(this).parent().find('i:last-child').removeClass('fa-angle-down').addClass('fa-angle-up');
            $('.page-sidebar-menu').css('height', "");
        }
    });

    $('.page-sidebar-menu li').click(function () {
        if ($(this).data("ref") == "card") {
            var height = $("#imgClientLogo").css('height');
            var strSbHeight = $(".page-sidebar-menu").css('height');
            if ($(this).parent().children('.sub-menu').is(':visible')) {
                var strSbHeight = 'calc(' + height + ' + 20px ' + ' - ' + strSbHeight + ')';
            } else {
                var strSbHeight = 'calc(' + height + ' + 20px ' + ' + ' + strSbHeight + ')';
            }
            $('.page-sidebar-menu').css('height', strSbHeight);
        }
    });
    // END SIDEBAR

    // BEGIN TOOLTIP
    if (isTouchDevice() === false) {
        $('[data-toggle="tooltip"]').tooltip();
    }
    // END TOOLTIP

    // BEGING RESPONSIVE PROFILE BUTTON
    $('#profile-button').click(function () {
        $("#profile-div").html($("#navuser").html());
        if (!$('#profile-div').is(':visible')) {
            $("#profile-div").show();
        } else {
            $("#profile-div").hide();
        }

        $('#preference_button').click(function () {
            $("#profile-div").hide();
        });
    });
    // END RESPONSIVE PROFILE BUTTON

    // FUNCTION FOR COUNT NOTIFICATIONS
    function countNotifications() {
        return $('#notifications').find('.notification-text').length;
    }

    // END FUNCTION FOR COUNT NOTIFICATIONS

    $('#notification-count').html(countNotifications());
    $('#notification-count-links').html(countNotifications());

    // NOTIFICATION MODAL MONT CONTENT
    function populateNotificationModal() {
        notifications = $('#notifications').html();
        // COPY ALL NOTIFICATIONS TO MODAL AND REMOVE LINK TO SHOW ALL NOTIFICATIONS
        $('#ModalNotifications').find('.notifications').html(notifications).find('.notification-link').remove();

    }

    populateNotificationModal();
    // ENF NOTIFICATION MODAL

    // CHANGE PAGE-CONTAINER HEIGHT ON SCROLL
    if ($('.page-container').length) {
        if ($('.page-container').data().inicialHeight <= 0) {
            $('.page-container').data().inicialHeight = $('.page-container').innerHeight();
        }
        if ($('.page-container').data().inicialPadding <= 0) {
            $('.page-container').data().inicialPadding = parseInt($('.page-container').css('padding-top'));
        }
    }

    $(window).scroll(function (event) {
        if ($('.page-container').length) {
            var myDiv = $('.page-container');

            var st = $(this).scrollTop();

            var newHeight = myDiv.data().inicialHeight - st;

            var newPadding = myDiv.data().inicialPadding - st;

            var minHeight = myDiv.data().minHeight;

            if (newHeight > minHeight) {
                myDiv.innerHeight(newHeight);
                myDiv.css('padding-top', newPadding + 'px');
            }

            if (newHeight <= minHeight) {
                myDiv.innerHeight(minHeight);
                myDiv.css('padding-top', '5px');
            }

            if (newHeight >= myDiv.data().inicialHeight) {
                if ($('.page-container').hasClass('shadown-menu')) {
                    $('.page-container').removeClass('shadown-menu');
                }
            } else {
                if (!$('.page-container').hasClass('shadown-menu')) {
                    $('.page-container').addClass('shadown-menu');
                }
            }
        }
    });

    // MODAL 100% HEIGHT
    function resizeModal() {
        vpw = $(window).width();
        vph = $(window).height();


        // FORCE SHOW SIDEBAR
        if (vpw > 780) {
            if (!$('#sidebar').is(':visible')) {
                $('#sidebar').toggle();
            }

        }
    }

    $(document).ajaxComplete(function () {
        resizeModal();
    });
    resizeModal();
    $(window).resize(resizeModal);
    // END MODAL


    // SIDEBAR BUTTON
    $('#sidebar-button').click(function () {
        $('#sidebar').slideToggle('fast');
    });
    // END SIDEBARBUTTON

    // BEGIN MENU FLUTUANTE
    $('.menu-flutuante-date').click(function () {
        $('.menu-flutuante-dropdown').toggle();
        if ($('#menu-flutuante-period-custom').is(':visible')) {
            toggle_menu_flutuante_period();
        }
    });
    $('#menu-flutuante-choose-period').click(function () {
        toggle_menu_flutuante_period();
    });
    // END MENU FLUTUANTE

    // BEGIN SEARCH BUTTON
    $('.btn-search').click(function (e) {

        e.stopPropagation();

        $('#div-search').animate({width: 'toggle'});
        if (!$('.input-search').width() > 0) {
            if ($(window).width() <= 1370) {
                $('.filter-dropdown').hide();
            }
            $('.input-search-h').focus();
        } else {
            if ($(window).width() <= 1370) {
                $('.filter-dropdown').hide();
            }
            $('.btn-search').blur();
        }
    });
    // END SEARCH BUTTON

    // START CLEAR SEARCH
    $(".btn-clear-search").click(function () {
        $(".input-search-h").val('');
        $('.input-search-h').blur();
    });
    // END CLEAR SEARCH

    // BEGIN PRINT BUTTON
    $('.btn-print').click(function () {
        $('.check-contestacoes').addClass('no-print');
        $('.page-header').addClass('no-print');
        $('.btn').addClass('no-print');
        window.print();
    });
    // END PRINT BUTTON

    // BEGIN FILTER BUTTON
    $('.btn-filter').click(function (e) {
        e.stopPropagation();
        $('.filter-dropdown').toggle();
    });
    // END FILTER BUTTON

    // LOGIN PAGE BUTTONS
    $('.login-menu a').click(function () {
        // CHANGE LINK CLASS
        $('.login-menu a').removeClass('active');
        $(this).addClass('active');

        // CHANGE FORM
        $('form').hide();
        $('#' + $(this).data('form')).show();
    });
    // END LOGIN PAGE BUTTONS

    // CARD OPTIONS
    $('.card-image').hover(function () {
        $(this).parent().find('.card-options').fadeIn('fast');
    }, function () {
        $(this).parent().find('.card-options').fadeOut('fast');
    });
    // END CARD OPTIONS

    // FUNCTION TO SHOW ERRORS
    function showMessage(error, type, noClose) {
        var close = '';

        if (noClose) {
            close = 'no-close'
        }

        return '<div class="alert alert-' + type + ' alert-dismissible fade in ' + close + '" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' + error + '</div>';
    }

    // END SHOW ERRORS

    // INPUT VALUE
    if ($('body').has('#charge-total').lenght) {
        $('#charge-total').html('R$ ' + [parseFloat($('#charge-value').val().replace('.', '').replace(',', '.')), 2].sum().format(2, '.', ','));
    }

    $("#charge-value").keyup(function (e) {
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
        calculateFee();
    });
    $("#charge-value").blur(function () {
        calculateFee();
    });
    // END INPUT VALUE

    $('#ModalCheckPassword').on('show.bs.modal', function (e) {
        $('#check-password-password', $(this)).val('');
    });

    $('.modal').on('show.bs.modal', function (e) {
        $('.alert', $(this)).each(function () {
            $(this).remove();
        });
    });

    $('html').on('submit', '.check-password', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!$(this).hasClass('form-ajax')) {
            check_password(false, $(this));
        }
    });

    $('html').on('submit', '.change-card-data', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!$(this).hasClass('form-ajax')) {
            check_password(false, $(this));
        }
    });

    function check_password(modalOrigin, frmOrigin) {
        if (modalOrigin.length) {
            modalOrigin.modal('hide');
        }
        $('html').on('submit', '#form-check-password', function (e) {
            e.preventDefault();
            e.stopPropagation();
            frmCheck = $(this);
            $.when(
                $.ajax({
                    type: frmCheck.attr('method'),
                    url: frmCheck.attr('action'),
                    data: frmCheck.serialize(),
                    beforeSend: function () {
                        frmCheck.find('.btn').hide();
                        frmCheck.append(loading);
                    },
                    success: function (response, status, xhr) {
                        frmCheck.find('.loading').remove();
                        frmCheck.find('.btn').show();

                        var obj = jQuery.parseJSON(response);
                        if (obj.hasOwnProperty('error')) {
                            frmCheck.prepend(showMessage(obj.error, 'danger'));
                        } else if (typeof obj.response == 'boolean' && obj.response) {
                            $('html').off('submit', '#form-check-password');

                            $('#ModalCheckPassword').on('hidden.bs.modal', function (e) {
                                if (modalOrigin.length) {
                                    modalOrigin.modal('show');
                                    modalOrigin.modal('handleUpdate');
                                }

                            });

                            $('#ModalCheckPassword').modal('hide');

                            if (frmOrigin.hasClass('form-ajax')) {
                                $.ajax({
                                    type: frmOrigin.attr('method'),
                                    url: frmOrigin.attr('action'),
                                    data: frmOrigin.serialize(),
                                    beforeSend: function () {
                                        frmOrigin.find('.btn').hide();
                                        frmOrigin.append(loading);
                                    },
                                    success: function (response, status, xhr) {
                                        frmOrigin.find('.alert').each(function () {
                                            $(this).remove();
                                        });
                                        var obj = jQuery.parseJSON(response);
                                        if (obj.hasOwnProperty('error')) {
                                            frmOrigin.prepend(showMessage(obj.error, 'danger'));
                                        } else if (obj.hasOwnProperty('success')) {
                                            frmOrigin.prepend(showMessage(obj.success, 'success'));
                                            location.reload();
                                        } else {
                                            frmOrigin.prepend(showMessage(frmOrigin.data('msg-success'), 'success'));
                                            location.reload();
                                        }
                                        frmOrigin.find('.loading').remove();
                                        frmOrigin.find('.btn').show();
                                    }, error: function () {
                                        frmOrigin.prepend(showMessage('Tente novamente', 'danger'));
                                        frmOrigin.find('.loading').remove();
                                        frmOrigin.find('.btn').show();
                                    }
                                });
                            } else {
                                $('html').off('submit', '.check-password');
                                frmOrigin.submit();
                            }
                        } else {
                            frmCheck.prepend(showMessage('Erro ao autenticar', 'danger'));
                        }
                    }, error: function () {
                        frmCheck.prepend(showMessage('Tente novamente', 'danger'));
                        frmCheck.find('.loading').remove();
                        frmCheck.find('.btn').show();
                    }
                })
            ).then(function () {
                $(".alert-danger").fadeTo(5000, 500, function () {
                    $(this).fadeOut(function () {
                        $(this).remove();
                    });
                });
            });
        });

        $('#ModalCheckPassword').modal('show');
    }

    // SUBMIT FORMS AJAX
    $('.form-ajax').submit(function (e) {
        modalOrigin = $(this).closest(".modal");
        // text
        e.preventDefault();

        frm = $(this);

        if (frm.valid()) {
            if ($(frm).hasClass('check-password') && $('#form-check-password').length) {
                check_password(modalOrigin, frm);
            } else {
                $.ajax({
                    type: frm.attr('method'),
                    url: frm.attr('action'),
                    data: frm.serialize(),
                    beforeSend: function () {
                        frm.find('.btn').hide();
                        frm.append(loading);
                    },
                    success: function (response, status, xhr) {
                        var obj = jQuery.parseJSON(response);

                        var noClose = null;
                        var noRedirect = null;

                        if (frm.hasClass('form-noclose'))
                            noClose = true;

                        if (frm.hasClass('form-noredirect'))
                            noRedirect = true;

                        if (obj.hasOwnProperty('error')) {
                            frm.prepend(showMessage(obj.error, 'danger', noClose));
                        } else if (obj.hasOwnProperty('success')) {
                            frm.prepend(showMessage(obj.success, 'success', noClose));
                            if (!noRedirect)
                                location.reload();
                        } else {
                            frm.prepend(showMessage(frm.data('msg-success'), 'success', noClose));
                            if (!noRedirect)
                                location.reload();
                        }

                        frm.find('.loading').remove();
                        frm.find('.btn').show();
                    }, error: function () {
                        frm.prepend(showMessage('Tente novamente', 'danger'));
                        frm.find('.loading').remove();
                        frm.find('.btn').show();
                    }
                });
            }

        }
    });
    // END SUBMIT FORMS

    //FORM ADDCARD
    $('#form_addcard').submit(function (e) {

        e.preventDefault();

        if (!$("#form_addcard").valid()) {
            return false;
        }

        frm = $(this);
        // load ajax on click button
        $.ajax({
            type: frm.attr('method'),
            url: frm.attr('action'),
            data: frm.serialize(),
            beforeSend: function () {
                frm.find('.btn').hide();
                frm.append(loading);
            },
            success: function (response, status, xhr) {
                var obj = jQuery.parseJSON(response);

                if (obj.status == "cardnotfound") {
                    msg = showMessage('Cartão não encontrado', 'danger');
                    $('#addcard-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                    frm.find('.loading').remove();
                    frm.find('.btn').show();

                } else if (obj.status == "linkedcard") {
                    msg = showMessage('Cartão já vinculado a uma conta', 'danger');
                    $('#addcard-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                    frm.find('.loading').remove();
                    frm.find('.btn').show();
                } else if (obj.status == "cardnotactive") {
                    msg = showMessage('O cartão que você digitou não está ativo, para ativá-lo saia da sua conta e siga o processo de ativação', 'danger');
                    $('#addcard-messages').html(msg).fadeTo(10000, 500).slideUp(500);
                    frm.find('.loading').remove();
                    frm.find('.btn').show();
                } else if (obj.status == "genericerror") {
                    msg = showMessage('Ocorreu um erro ao tentar adicionar o cartão, por favor tente novamente mais tarde', 'danger');
                    $('#addcard-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                    frm.find('.loading').remove();
                    frm.find('.btn').show();
                } else {
                    frm.hide();
                    frm.parent().find('.charge-success').show();
                    frm.parent().find('#charge-number').html(obj.ret);
                    $("#card_number").val("");
                    $("#date_birth").val("");

                    var timer = setTimeout(function () {
                        location.reload();
                        clearTimeout(timer);
                    }, 1000);
                }
            }
        });
    });
    // END FORM ADDCARD

    // FORM ADD FAVORED
    if ($('#form_addfavored').length > 0) {

        $('#form_addfavored').validate({
            rules: {
                favoredDocument: {
                    required: true,
                    cpf: true
                },
                favoredCard: {
                    required: true,
                    number: true,
                    maxlength: 4,
                    minlength: 4
                },
                favoredId: {
                    required: true
                }
            }
        });

        $('*[name="favoredDocumentType"]').click(function (e) {
            e.stopPropagation();
            $('#favoredDocument').unmask();
            $('#favoredDocument').rules('remove');
            $('#favoredDocument').rules('add', {required: true});
            switch ($(this).val()) {
                case 'CPF':
                    $('#favoredDocument').mask('000.000.000-00');
                    $('#favoredDocument').rules('add', {cpf: true});
                    break;
                case 'CNPJ':
                    $('#favoredDocument').mask('00.000.000/0000-00');
                    $('#favoredDocument').rules('add', {cnpj: true});
                    break;
                default:
                    $('#favoredDocument').mask('#####################0');
                    $('#favoredDocument').rules('add', {required: true});
                    break;
            }

            $('input[type=radio][name=favoredDocumentType]').blur();

            $('#favoredDocument').valid();
        });

        $('#favoredFind').attr('disabled', 'disabled');

        $('#favoredDocument').on('keyup', function(e) {
            if($(this).valid() && parseInt($(this).val()) != 0) {
                $('#favoredFind').removeAttr('disabled');
            } else {
                $('#favoredFind').attr('disabled', 'disabled');
            }
        });

        $('*[name="favoredDocumentType"]:eq(0)').trigger('click');

        $('#favoredFind').click(function () {
            var $form = $('#form_addfavored');
            var $btn = $(this);
            $.ajax({
                type: 'GET',
                url: $form.data('search'),
                dataType: 'json',
                data: {
                    document: $('#favoredDocument').val(),
                    card4digits: $('#favoredCard').val(),
                    addFavored: true
                },
                beforeSend: function () {
                    $btn.button('loading');
                    $('#favoredId').html('');
                }
            }).done(function (data) {
                $('#favoredId').html('<option value="">Selecione</option>');
                $.each(data.cards, function (i, e) {
                    if (i != $('#favoredId').val()) {
                        $("#favoredId").append($("<option />").val(i).text(e));
                    }
                });
                $("#form_addfavored").valid()
            }).fail(function () {
                $('#favoredId').html('<option value="">Nenhum cartão encontrado</option>');
            }).always(function () {
                $btn.button('reset');
            });
        });

        $('#form_addfavored').submit(function (e) {
            e.preventDefault();

            if (!$("#form_addfavored").valid()) {
                return false;
            }

            frm = $(this);
            // load ajax on click button
            $.ajax({
                type: frm.attr('method'),
                url: frm.attr('action'),
                data: frm.serialize(),
                beforeSend: function () {
                    frm.find('.btn').hide();
                    frm.append(loading);
                },
                success: function (response, status, xhr) {
                    var obj = jQuery.parseJSON(response);

                    if (obj.status == "cardnotfound") {
                        msg = showMessage('Cartão não encontrado', 'danger');
                        $('#addfavored-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                        frm.find('.loading').remove();
                        frm.find('.btn').show();

                    } else if (obj.status == "cardnotactive") {
                        msg = showMessage('O cartão que você digitou não está ativo, para ativá-lo saia da sua conta e siga o processo de ativaçã', 'danger');
                        $('#addfavored-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                        frm.find('.loading').remove();
                        frm.find('.btn').show();

                    } else if (obj.status == "linkedcard") {
                        msg = showMessage('Cartão já vinculado a uma conta', 'danger');
                        $('#addfavored-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                        frm.find('.loading').remove();
                        frm.find('.btn').show();
                    } else {
                        frm.hide();
                        frm.parent().find('.charge-success').show();
                        frm.parent().find('#favored-number').html(obj.ret);
                        $('#form_addfavored')[0].reset();

                        var timer = setTimeout(function () {
                            location.reload();
                            clearTimeout(timer);
                        }, 1000);
                    }
                }
            });
        });
    }

    // END FAVORED

    //FORM REMOVECARD
    $('#form_removeCard').submit(function (e) {

        e.preventDefault();
        frm = $(this);
        // load ajax on click button
        $.ajax({
            type: frm.attr('method'),
            url: frm.attr('action'),
            data: frm.serialize(),
            beforeSend: function () {
                frm.find('.btn').hide();
                frm.append(loading);
            },
            success: function (response, status, xhr) {
                var obj = jQuery.parseJSON(response);

                if (obj.status == "cardremove") {
                    frm.hide();
                    frm.parent().find('.charge-success').show();
                    var timer = setTimeout(function () {
                        location.reload();
                        clearTimeout(timer);
                    }, 1000);

                } else {
                    msg = showMessage('Cartão não pode ser removido', 'danger');
                    $('#delcard-messages').html(msg).fadeTo(5000, 500).slideUp(500);
                    frm.find('.loading').remove();
                    frm.find('.btn').show();
                }
            }
        });
    });
    // END FORM REMOVECARD

    // FORM ACCOUNT PREFERENCES
    jQuery.validator.addMethod("greaterThan", function (value, e) {
        var date = value.split("/"),
            day = date[0],
            month = date[1],
            year = date[2];
        day = parseFloat(day);
        month = parseFloat(month);
        year = parseFloat(year);

        var data = new Date();
        var dia = data.getDate();
        if (dia.toString().length == 1)
            dia = "0" + dia;
        dia = parseFloat(dia);
        var mes = data.getMonth() + 1;
        if (mes.toString().length == 1)
            mes = "0" + mes;
        mes = parseFloat(mes);
        var ano = data.getFullYear();
        ano = parseFloat(ano);

        // When same year: day and month must be equal or lower
        if (year == ano) {
            if (month > mes) {
                return false;
            } else if (month == mes) {
                if (day > dia) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else if (year > ano) {
            return false;
        } else {
            return true;
        }

    }, 'A data informada deve ser menor que a data atual.');

    $("#form-account-preferences").validate({
        rules: {
            gender: {
                required: true,
                minlength: 1,
                maxlength: 1
            },
            dateBirth: {
                date: true,
                greaterThan: true
            },
            phone1: {
                required: true,
                minlength: 10,
                maxlength: 15
            },
            phone2: {
                required: true,
                minlength: 10,
                maxlength: 15
            }
        }
    });

    $("#form-card-data").validate({
        rules: {
            dateBirth: {
                date: true,
                greaterThan: true
            },
            phone1: {
                required: true,
                minlength: 10,
                maxlength: 15
            },
            phone2: {
                required: true,
                minlength: 10,
                maxlength: 15
            },
            mobile: {
                required: true,
                minlength: 10,
                maxlength: 15
            },
            address: {
                maxlength: 50
            },
            addressNumber: {
                maxlength: 10
            },
            complement: {
                maxlength: 50
            }
        }
    });
    // END ACCOUNT PREFERENCES

    $("#form-address").validate({
        rules: {
            zipcode: {
                required: true,
                minlength: 9
            },
            address: {
                required: true,
                maxlength: 50
            },
            addressNumber: {
                required: true,
                maxlength: 10
            },
            complement: {
                maxlength: 50
            },
            district: {
                required: true,
                maxlength: 50
            },
            city: 'required',
            hdnPhone2:{
                required: true
            }
        },
        messages:{
            hdnPhone2:{
                required: "O campo Celular é obrigatório."
            },
        }
    });

    $("#change-password-form").validate({
        rules: {
            oldPassword: {
                required: true
            },
            newPassword: {
                required: true,
                checkPassword: true
            },
            confirmPassword: {
                required: true,
                equalTo: '#newPasswordAccount'
            },
            hdnPhone2:{
                required: true
            }
        },
        messages: {
            confirmPassword: {
                equalTo: 'A confirmação da senha não confere.'
            },
            hdnPhone2:{
                required: "O campo Celular é obrigatório."
            },
        }
    });

    $("#change-password-form-card").validate({
        rules: {
            newPassword: {
                required: true,
                minlength: 4,
                maxlength: 4,
            },
            confirmPassword: {
                required: true,
                minlength: 4,
                maxlength: 4,
                equalTo: '#newPassword'
            }
        },
        messages: {
            confirmPassword: {
                equalTo: 'A confirmação da senha não confere.'
            }
        }
    });


    // CEP SEARCH
    $('#zipcode').change(function () {
        zipcodeObj = $(this);
        $('#address').attr('readonly', 'readonly');
        $('#city').attr('readonly', 'readonly');
        $('#district').attr('readonly', 'readonly');
        var cep = $(this).val().replace('-', '');
        var confirmGiftBtn = $(document).find("#btnGiftCardDocument").prop('nodeName');
        // load ajax on click button
        $.ajax({
            type: 'GET',
            url: '/service/address/' + cep,
            beforeSend: function () {
                zipcodeObj.parent().append(loading);
                zipcodeObj.parent().find('label.error').remove();

                if(confirmGiftBtn != 'undefined'){
                    $("#btnGiftCardDocument").attr('disabled', true);
                }
            },
            success: function (response, status, xhr) {
                $('#zipcode').parent().find('.loading').remove();
                var obj = jQuery.parseJSON(response);
                if (obj.hasOwnProperty('error')) {
                    zipcodeObj.parent().append('<label class="error">' + obj.error + '</label>');
                    $('#address').removeAttr('readonly');
                    $('#city').removeAttr('readonly');
                    $('#district').removeAttr('readonly');
                } else {
                    zipcodeObj.parent().find('.error').remove();
                    
                    /** DADOS DE LOGRADOURO */
                    $address = $('#address');
                    if(obj.street1 !== ''){
                    	$address.val(obj.street1).attr('readonly', 'readonly');
                    }else{
                    	$address.val('');
                    	$('#address').removeAttr('readonly');
                    }
                    $address.valid();
                    
                    /** DADOS DE CIDADE */
                    $city = $('#city');
                    $city.val(obj.city).attr('readonly', 'readonly');
                    $city.valid();
                    
                    /** ESTADO */
                    $state = $('#state');
                    $state.val(obj.state);
                    
                    /** DADOS DE BAIRRO */
                    $district = $('#district');
                    if(obj.district !== ''){
                    	$district.val(obj.district).attr('readonly', 'readonly');
                    }else{
                    	$district.val('');
                    	$('#district').removeAttr('readonly');
                    }
                    
                    $district.valid();
                    
                }
                if(confirmGiftBtn != 'undefined'){
                    $("#btnGiftCardDocument").attr('disabled', false);
                }
            }
        });
    });
    // END CEP SEARCH

    // ALERT AUTO HIDE
    $(".alert").each(function () {
        if (!$(this).hasClass('no-close')) {
            $(this).fadeTo(8000, 500).slideUp(500);
        }
    });
    // END ALERT AUTO HIDE

    // SIDEBAR ACTIVE ITEM
    activeRoute = window.location.pathname.split('/');

    sidebar_item = activeRoute[activeRoute.length -1];

    if (sidebar_item == 'account') {
        var sidebar_obj = $('.page-sidebar-menu').find('[data-ref="card"]');
        if (sidebar_obj.has('.sub-menu')) {
            sidebar_obj.find('.sub-menu').toggle();
        }
    }

    if (activeRoute.length >= 4) {
        if(activeRoute[3] == 'report') {
            sidebar_item = activeRoute[3];
        }
    }

    var card_item = activeRoute[3];
    $('.page-sidebar-menu').find('[data-card-id="' + card_item + '"]').addClass('active');

    var sidebar_obj = $('.page-sidebar-menu').find('[data-ref="' + sidebar_item + '"]');
    sidebar_obj.addClass('active');
    if (sidebar_obj.has('.sub-menu')) {
        sidebar_obj.find('.sub-menu').toggle();
    }
    // END SIDEBAR ACTIVE ITEM

    // NAVSELECT FOR MOBILE
    $('.nav-select').on('click', 'li', function () {
        t = $(this).children('a').html();
        $(this).parent().parent().children('button').html(t);
    });

    $('.nav-select ul').each(function () {
        t = $(this).children('li').children('a:first-child').html();
        $(this).parent().children('button').html(t);
    });

    // APP FOR MOBILE FUNCTION
    if (typeof InterfaceNative != 'undefined') {
        if (typeof InterfaceNative.getTypeDevice == 'function') {
            var ret = InterfaceNative.getTypeDevice();
        }
    }
    //

    // BEGIN MASK
    $('input[mask="phone"]').mask('(00) 0000-0000');
    $('input[mask="phone-nine"]').mask('(00) 0000-00009');
    $('input[mask="phone-international"]').mask('000000000099999');
    $('input[mask="cpf"]').mask('000.000.000-00');
    $('input[mask="cnpj"]').mask('00.000.000/0000-00');
    $('input[mask="date"]').mask('00/00/0000');
    $('input[mask="date_mmyy"]').mask('00/00');
    $('input[mask="cep"]').mask('00000-000');
    $('input[mask="4digits"]').mask('0000');
    $('input[mask="card-number"]').mask('0000.0000.0000.0000');
    $('input[mask="decimal"]').mask('0#.##0,00', {reverse: true});
    $('input[mask="number"]').mask('#####################0');

    $('#document_type').change(function () {
        var mask = ['000.000.000-00', '00.000.000/0000-00', '#####################0'];
        var element = $(this).attr('data-target');
        $('#' + element).unmask();
        $('#' + element).mask(mask[$(this).val()]);
    });

    $('#form-account-create #documentoCPF').click(function () {
        $('#form-account-create #documento').mask('000.000.000-00');
        $('#linha_nome_fantasia').hide();
        $('#linha_nacionalidade').hide();
        $('#linha_data_nascimento').show();
        $('#linha_data_fundacao').hide();

        $('#linha_nome').find('label').text('Nome');

        //limpar campo
        $("#documento").val("");
        $('#documento').rules('remove');
        $('#documento').rules('add', {cpf: true});


        $("label.error").hide();
        $(".error").removeClass("error");

    });

    $('#form-account-create #documentoCNPJ').click(function () {

        $('#form-account-create #documento').mask('00.000.000/0000-00');
        $('#linha_nome_fantasia').show();
        $('#linha_nacionalidade').hide();
        $('#linha_data_nascimento').hide();
        $('#linha_data_fundacao').show();

        $('#linha_nome').find('label').text('Razão Social');

        //limpar campo
        $("#documento").val("");
        $('#documento').rules('remove');
        $('#documento').rules('add', {cnpj: true});

        $("label.error").hide();
        $(".error").removeClass("error");

    });

    $('#form-account-create #documento').mask('000.000.000-00');
    $('#linha_nome_fantasia').hide();
    $('#linha_nacionalidade').hide();
    $('#linha_data_nascimento').hide();
    $('#linha_data_fundacao').hide();
    // END MASK

    $('#linha_data_nascimento').show();

    if ($('#form-account-create').length) {
        $('#documento').on('focus', function () {
            $('.OthersSteps').addClass('hidden');
            $("#DocumentBtn").removeClass('hidden');
        });

        $("#DocumentBtn").on('click', function (e) {
            e.preventDefault();
            var documento = $("#documento").val();
            if (documento.length > 0) {
                $('.MessagesSteps').hide();
                $(".OthersSteps").addClass('hidden');
                toggleButtonLoading('#DocumentBtn');
                $.when(
                    $.ajax({
                        type: 'POST',
                        url: urlSearchAccountByDocument,
                        data: {'documento': documento, _token: cpfToken, 'checkExistingAccount': 'true', 'notCreateUsernameAndPassword': 'true'},
                        complete: function (response) {
                            var data = JSON.parse(response.responseText);
                            if (data.Result == null) {
                                $(".OthersSteps").removeClass('hidden');
                                $("#document_find").addClass("hidden");
                                $("#hide_button_recover").addClass("hidden");
                                $(".load_create_account").hide();
                                $("#DocumentBtn").addClass('hidden');
                                $("#documento").attr('readonly', 'readonly');

                            }
                            else if (data.Result.msg == "user found") {
                                $(".OthersSteps").addClass('hidden');
                                $('#document_find_message').html("Seu documento já possui uma conta. Clique em recuperar senha caso não lembre sua senha.");
                                $("#document_find").removeClass("hidden");
                                $("#hide_button_recover").removeClass("hidden");
                                $('html, body').animate({scrollTop: $('#document_find').offset().top - 50}, 'slow');

                            } else if (data.Result.msg == "user not found") {

                                // hide others fiends
                                $(".OthersSteps").addClass('hidden');
                                var name = data.Result.name;
                                var email = data.Result.email;
                                var token = data.Result.token;
                                $.ajax({
                                    type: 'POST',
                                    async: false,
                                    url: sendEmailCreateUser,
                                    data: {'name': name, 'email': email, 'token': token, _token: cpfToken}
                                }).done(function () {
                                    $("#form-account-create").attr("action", "send_email_create_user");
                                    $("#form-account-create").submit();
                                });
                            }
                            else if (data.Result.msg == "notPermited" || data.Result.msg == "giftError") {
                                e.preventDefault();
                                e.stopPropagation();
                                $(".accountDenyAtt").text(data.Result.errInfo);
                                $("#accountDenyAtt").removeClass('hidden');
                                $("#accountDenyAtt").show();
                                $('html, body').animate({
                                    scrollTop: $(".login-header").offset().top
                                }, 100);
                                $("#accountDenyAtt").each(function () {
                                    $(this).delay(8000).slideUp(300);
                                });
                            } else if (data.Result.msg == "user not found cpf") {

                                // hide others fiends
                                $(".OthersSteps").addClass('hidden');
                                var name = data.Result.name;
                                var email = data.Result.email;
                                var token = data.Result.token;
                                
                                $("#form-account-create").attr("action", "msg_create_user_cpf");
                                $("#form-account-create").submit();
                                
                            }
                        }
                    })
                ).then(function () {
                    $('.MessagesSteps').show();
                    toggleButtonLoading('#DocumentBtn');
                });

            } else {
                $('#document').focus();
            }
        });
    }

    if ($('#form-giftcard-account').length) {

        var $document = $("#documentNumber");
        $document.mask('000.000.000-00');

        $('#form-giftcard-account').validate({
            rules: {
                documentNumber: {
                    required: true,
                    cpf: true
                },
                zipcode: {
                    required: true
                },
                data_fundacao: {
                    date: true
                },
                data_nascimento: {
                    date: true
                },
                nome: {
                    fullname: true,
                    required: true
                },
                mother_name: {
                    required: true,
                    fullname: true
                },
                celular: {
                    required: true,
                    minlength: 10,
                    maxlength: 15
                }
            }
        });

        $("input[name='documentType']:radio").on('change', function (e) {
            $('#btnGiftCardDocument').attr('disabled', 'disabled');

            var $document = $("#documentNumber");

            $document.val('');
            $document.unmask();
            $document.rules('remove');

            $document.rules('add', 'required');

            if ($(this).val() == 'CPF') {
                $document.rules('add', {cpf: true});
                $document.mask('000.000.000-00');
                $('#field_nacionalidade').addClass('hidden');
            } else if ($(this).val() == 'CNPJ') {
                $document.rules('add', {cnpj: true});
                $document.mask('00.000.000/0000-00');
                $('.field-cnpj').removeClass('hidden');
            } else {
                $('.field-cnpj').addClass('hidden');
            }

            $document.valid();

        });

        $document.on('keyup', function (e) {
            $('.OtherSteps').addClass("hidden");
            $('.StepData').addClass("hidden");
            $('.StepUsername').addClass("hidden");
            $('.StepLogin').addClass('hidden');
            step = "document";
            if ($(this).val() != '' && $(this).valid()) {
                $('#btnGiftCardDocument').removeAttr('disabled');
            } else {
                $('#btnGiftCardDocument').attr('disabled', 'disabled');
            }
        });

        function checkSms(params, documento){
        	
        	var resposta = false;
        	
        	$.ajax({
                type: 'GET',
                url: "/service/check_mobile_token/" + params + "/" + documento,
                dataType : 'json',
                success: function(data) {
                	resposta = data;
                },
                error: function(jqXHR, textStatus, errorThrown) {
                	resposta = false;
                }
        	}).done(function (data) {
        		resposta = data;
        	});
        	
        	return resposta;
        	
        }

        $('#btnGiftCardDocument').on('click', function (e) {
            e.preventDefault();
            $("#giftDenyAtt").addClass('hidden');
            $(".giftDenyAtt").text("");
            
            switch (step) {
                case "document":
                    if ($document.val() != '') {
                        $document.attr('disabled', 'disabled');
                        toggleButtonLoading('#btnGiftCardDocument');
                        $('.StepData').addClass('hidden');
                        $('.OtherSteps').addClass('hidden');
                        $.when(
                            $.ajax({
                                type: 'POST',
                                url: urlSearchAccountByDocument,
                                data: {'documento': $('#documentNumber').val(), _token: cpfToken},
                                complete: function (response) {
                                    var data = JSON.parse(response.responseText);
                                    if (data.Result == null) {
                                        $('#account_type').val('new');
                                        $('.StepData').removeClass('hidden');
                                        $('.StepUsername').removeClass('hidden');
                                        $('#login').rules('add', {
                                            required: true
                                        });
                                        $('#senha').rules('add', {
                                            checkPassword: true,
                                            required: true
                                        });
                                        $('#conf_senha').rules('add', {
                                            equalTo: '#senha',
                                            required: true
                                        });
                                        step = "data";
                                    }
                                    else if (data.Result.msg == "giftError") {
                                        e.preventDefault();
                                        $(".giftDenyAtt").text(data.Result.errInfo);
                                        $("#giftDenyAtt").removeClass('hidden');
                                        $('html, body').animate({
                                            scrollTop: $(".login-header").offset().top
                                        }, 100);
                                    }
                                    else if (data.Result.msg == "user found") {
                                    	
                                    	/** SETA DOCUMENTO COMO USERNAME */
                                    	$('#username').val($('#documentNumber').val());
                                    	
                                        $('#account_type').val('exists');
                                        $('.StepLogin').removeClass('hidden');
                                        $('.StepUsername').addClass('hidden');
                                        $('#login').rules('remove');
                                        $('#senha').rules('remove');
                                        $('#conf_senha').rules('remove');
                                        step = "login";
                                        $('html, body').animate({
                                            scrollTop: $('.StepLogin').offset().top
                                        });
                                    } else if (data.Result.msg == "user not found") {
                                        $('#account_type').val('pre_account');
                                        $('.StepData').removeClass('hidden');
                                        $('.StepUsername').removeClass('hidden');
                                        $('#login').rules('add', {
                                            required: true
                                        });
                                        $('#senha').rules('add', {
                                            checkPassword: true,
                                            required: true
                                        });
                                        $('#conf_senha').rules('add', {
                                            equalTo: '#senha',
                                            required: true
                                        });
                                        step = "data";
                                    }
                                    $('.OtherSteps').removeClass('hidden');

                                }
                            })
                        ).then(function () {
                            $('.OtherSteps').removeClass('hidden');
                            $document.removeAttr('disabled');
                            toggleButtonLoading('#btnGiftCardDocument');
                        });
                    }
                    break;
                case "login":
                	toggleButtonLoading('#btnGiftCardDocument');
                    $('#userNotFound').addClass('hidden');
                    $.when(
                        $.ajax({
                            type: 'POST',
                            url: urlAuthenticate,
                            data: {'username': $('#username').val(), 'password': $('#password').val(), _token: cpfToken, 'optTipoDoc': 'cpf'},
                            complete: function (response) {
                                var data = JSON.parse(response.responseText);
                                var error = true;
                                if (data.hasOwnProperty('result')) {
                                    if (data.result.hasOwnProperty('Result')) {
                                        if(data.result.Result.msg == "giftError") {
                                            $(".giftDenyAtt").text(data.result.Result.errInfo);
                                            $("#giftDenyAtt").removeClass('hidden');
                                            $('html, body').animate({
                                                scrollTop: $(".login-header").offset().top
                                            }, 100);
                                        }
                                    } else {
                                        if (data.result.hasOwnProperty('accountId')) {
                                            $('.StepData').removeClass('hidden');
                                            $('.StepLogin').addClass('hidden');
                                            step = "data";

                                            error = false;
                                        }
                                    }
                                }

                                if (error) {
                                    $('#userNotFound').removeClass('hidden');
                                    console.log("usuário inválido");
                                }
                            }
                        })
                    ).then(function () {
                        toggleButtonLoading('#btnGiftCardDocument');
                    });
                    break;
                default:
                    if($('#form-giftcard-account').valid()){
                        toggleButtonLoading('#btnGiftCardDocument');
                        if(!$("#codesms").hasClass('hidden')){
                            isValidSmsToken(true, '');
                            if($("#codesms").val() != ''){
                            	var smsCode = $("#codesms").val();
                                var number = $("#countryCode").val() + $("#celular").val();
                                var params = [smsCode, number];
                                
                                var documento = '';
                                if($('#username').val()){
                                	documento = $('#username').val();
                                }else{
                                	documento = $('#login').val();
                                }
                                
                                /** FAZ A VALIDAÇÃO DO TOKEN */
                                $.ajax({
                                    type: 'GET',
                                    url: "/service/check_mobile_token/" + params + "/" + documento,
                                    dataType : 'json',
                                    success: function(data) {
                                    	resposta = data;
                                    },
                                    error: function(jqXHR, textStatus, errorThrown) {
                                    	resposta = false;
                                    }
                            	}).done(function (data) {
                            		if(data){
                            			$("#form-giftcard-account").submit();
                            		}else{
                            			isValidSmsToken(false, 'Código inválido');
                                        $('html, body').animate({scrollTop: $('#codesms').offset().top - 50}, 'slow');
                                        toggleButtonLoading('#btnGiftCardDocument');
                            		}
                            	});
                            } else {
                                isValidSmsToken(false, 'Este campo é obrigatório');
                                $('html, body').animate({scrollTop: $('#codesms').offset().top - 50}, 'slow');
                                toggleButtonLoading('#btnGiftCardDocument');
                            }
                        } else {
                            $('#form-giftcard-account').submit();
                        }
                    }
                    break;
            }
        });
    }

    $("#codesms").on('keyup blur', function(){
        $("#codesms_err").hide();
    });

    function isValidSmsToken(enabled, message) {
        if (enabled) {
            $("#codesms").removeClass('error invalid');
            $("#codesms_err").addClass('hidden');
            $("#codesms_err").hide();
        } else {
            $("#codesms").addClass('error invalid');
            $("#codesms_err").text(message);
            $("#codesms_err").removeClass('hidden');
            $("#codesms_err").show();
        }
    }

    $(".search_login").on('blur', function () {
        var login = $("#login").val();
        if (login.length > 0) {
        	
        	$.ajax({
                type: 'POST',
                url: urlSearchAccountByUser,
                data: {'login': login, _token: cpfToken},
                beforeSend: function () {
                    $(".load_login").show();
                    $(".disabled_button").attr("disabled", true);
                },
                complete: function (response) {
                    var data = JSON.parse(response.responseText);

                    if (data.Result == null) {
                        $(".disabled_button").attr("disabled", false);
                        $("#document_find").addClass("hidden");
                        $("#hide_button_recover").addClass("hidden");
                        $(".load_login").hide();

                    }
                    else if (data.Result.msg == "user found") {
                        $('#document_find_message').html("Esse nome de login já existe. Escolha um novo nome de login ou clique em recuperar sua senha, caso você seja o dono do login e esqueceu sua senha.");
                        $("#document_find").removeClass("hidden");
                        $("#hide_button_recover").removeClass("hidden");
                        $(".disabled_button").attr("disabled", true);
                        /*window.scrollTo(400, 0);*/
                        if($('#document_find').length) {
                            $('html, body').animate({scrollTop: $('#document_find').offset().top - 150}, 'slow');
                        }
                        $(".load_login").hide();
                    }
                }
            });
        }
    });

    //sugerir email com login
    $(".suggest_email").on('blur ', function () {
        if ($('.search_login:visible').length) {

            //var email = $("#email").val();
            var doc = $("#documentNumber").val();
            //$("#login").val(email);
            $("#login").val(doc);
            $('.search_login').trigger('blur');
        }
    });

    //recuperar senha
    $("html").on("click", ".recoverpassord", function (e) {
        e.preventDefault();
        window.location.href = recoverPassword;
    });

    // page-menu-list click
    $('html').on('click', '.page-menu-list a', function () {
        $('.page-menu-list').find('.active').removeClass('active');
        $(this).addClass('active').blur();
        $(this).blur();
    });

    // active page link when closes card data modal
    $('#ModalPreferencesCard').on('hidden.bs.modal', function () {
        $('#changeCardDataLink').removeClass('active');
        $('#changeDataCardLink').removeAttr('class');

        if ($('.page-container-top .title h2').html() == 'Extrato')
            $('#showStatement').click();

        if ($('.page-container-top =.title h2').html() == 'Rachar Conta')
            $('#showSplitStatement').click();
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $('a[data-toggle="tab"]').parent('li').removeAttr('class');
        $(e.target).parent('li').addClass('active') // activated tab
    });

    $('.updatePasswordLink').on('click', function(){
        $('.nav-tabs a[href="#change_password"]').tab('show');
    });

    $('.changeCardDataLink').on('click', function(){
        $('.nav-tabs a[href="#info"]').tab('show');
    });

});

jQuery(window).on('error', function () {
    adjustNavBarCss();
    adjustSideBarCss();
});

jQuery(window).on('load', function () {
    adjustNavBarCss();
    adjustSideBarCss();
});


function getChargeFee() {
    id = $('#chargeTo').val();
    type = (id != "0") ? 'card' : 'account';
}

function calculateFee() {
    amount = $('#charge-value').val();
    fixed = parseFloat($('#feeFixed').val());
    percentage = parseFloat($('#feePercentage').val());
    valorFloat = ($('#charge-value').val() == '') ? 0.00 : parseFloat($('#charge-value').val().replace('.', '').replace(',', '.'));

    if (!isNaN(valorFloat) && !isNaN(fixed) && !isNaN(percentage)) {
        percentage = (percentage > 0) ? percentage / 100 : 0;
        fee = fixed + (valorFloat * percentage);
        $('#chargeFee').val(fee);
        $('#labelChargeFee').html(accounting.formatMoney(fee, "R$ ", 2, ".", ","));
        updateChargeValue();
    }
}

function updateChargeValue() {
    amount = parseFloat($('#charge-value').val().replace('.', '').replace(',', '.'));
    fee = parseFloat($('#chargeFee').val());
    valorFloat = amount + fee;
    $('#charge-total').html(accounting.formatMoney(valorFloat, "R$ ", 2, ".", ","));
}

function adjustNavBarCss() {
    var height = $("#imgClientLogo").css('height');
    var strCssHeight = 'calc(' + height + ' + 14px)';
    var strCssTop = 'calc(' + height + ' -  35px )';
    var strCssTopLogin = 'calc(' + height + ' -  10px )';
    var strLoginPdTop = '9px';

    $('.navbar-login-header').css('height', strCssHeight);
    $('.navbar-login-header').css('padding-top', strLoginPdTop);
    $('.navbar-brand').css('height', strCssHeight);
    $('.navbar-header').css('height', strCssHeight);

    $('#sidebar').css('margin-top', strCssTop);
    $('#transfer-summary').css('margin-top', strCssTop);
    $('.container-fluid').css('margin-top', strCssTop);
    $('#profile-div').css('margin-top', strCssTop);
    $('.login-data').css('margin-top', strCssTopLogin);
}

function adjustSideBarCss() {
    if ($('.page-sidebar-menu li a').parent().children('.sub-menu').is(':visible')) {
        var calc = $('.page-sidebar-menu').height() + $('.navbar-brand').height() + 10;
        $('.page-sidebar-menu').css('height', calc);
    } else {
        $('.page-sidebar-menu').css('height', "");
    }
}

function isTouchDevice() {
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}

$('.updatePasswordLinkConta').on('click', function(){
    $('.nav-tabs a[href="#messages"]').tab('show');
});

$('.updateInfoLinkConta').on('click', function(){
    $('.nav-tabs a[href="#home"]').tab('show');
});
